import { dehydrate, QueryClient } from '@tanstack/react-query';
import { Button, Modal } from 'litto-lib/components/common/';
import { getParameterByName } from 'litto-lib/helpers';
import {
	getImageUrl,
	getImageUrlFromSylius,
	getQueryKeyFromParams,
	isEmpty,
	newLinesToBr,
	truncateText
} from 'litto-lib/utils';
import { useRouter } from 'next/router';
import React from 'react';

import { AccommodationCard } from 'components/accommodation';
import { LocationCard, LocationHero } from 'components/location';
import {
	Card,
	LocationSlider,
	MasterSearch,
	RowLayout,
	SingleHeroSkeleton,
	TipsSlider
} from 'components/shared';
import { PlainLayout } from 'Layout';
import { NextSeo } from 'seo';
import {
	HydraCollectionResponse,
	ISimilarLocationRead
} from 'services/shop/interfaces';
import {
	getLocation,
	getLocations,
	getSimilarLocations,
	locationKeys,
	useGetLocationQuery
} from 'services/shop/location';
import { getTaxon, taxonsKeys, useGetTaxonQuery } from 'services/shop/taxon';
import { getPromoBannerData } from 'services/wordpress/data';
import { IPromoBannerData } from 'services/wordpress/data/interface';

interface ICitySingleProps {
	locationCode: string;
	similarLocations: HydraCollectionResponse<ISimilarLocationRead>;
	promoBanner: IPromoBannerData;
}

const CitySingle: React.FC<ICitySingleProps> = ({
	locationCode,
	similarLocations,
	promoBanner
}) => {
	const router = useRouter();
	const [modalMoreOpen, setModalMoreOpen] = React.useState(false);

	const { data: location } = useGetLocationQuery(locationCode, {
		enabled: !router.isFallback
	});

	const { data: accommodationTaxons } = useGetTaxonQuery('accommodation', {
		params: { location: locationCode }
	});

	if (router.isFallback) {
		return (
			<div className="container">
				<SingleHeroSkeleton className="my-10" />
			</div>
		);
	}

	if (!location) {
		return <></>;
	}

	return (
		<PlainLayout>
			<NextSeo
				title={`Holidays in ${location.translation.name}, Croatia | Book apartments, luxury villas and vacation rentals - LITTO`}
				description={`Best price guaranteed! Schedule your ${location.translation.name}, Croatia holiday with Litto and discover unforgettable holiday experiences on the Adriatic coast.`}
				openGraph={{
					images: [
						{ url: getImageUrlFromSylius(location.mainImage?.imagePath || '') }
					]
				}}
			/>
			<div className="relative">
				<div className="container my-6 overflow-x-hidden md:my-16">
					<LocationHero location={location} />
				</div>
				<div className="container top-2 z-50 mb-16 flex justify-center xl:sticky">
					<MasterSearch className="shadow-lg" selectedLocation={location} />
				</div>
				<div className="container">
					<RowLayout title={`About ${location.translation.name} in details`}>
						<div className="prose">
							<p
								dangerouslySetInnerHTML={{
									__html: truncateText(location.translation.description, 500)
								}}
							/>
							{location.translation.description.length > 500 && (
								<Button
									onClick={() => setModalMoreOpen(true)}
									size="sm"
									text="Show all info"
									rounded="full"
								/>
							)}
						</div>
					</RowLayout>
					<Modal
						headerText="Description"
						isOpen={modalMoreOpen}
						onRequestClose={() => {
							setModalMoreOpen(false);
						}}
					>
						{!!location?.translation?.description && (
							<div
								className="prose-lg prose"
								dangerouslySetInnerHTML={{
									__html: newLinesToBr(location.translation.description)
								}}
							/>
						)}
					</Modal>
					{location.latitude && location.longitude && (
						<RowLayout
							title="Location"
							subtitle={`${location.county} , Croatia`}
						>
							<LocationCard
								lat={location.latitude}
								lng={location.longitude}
								zoom={12}
								neighborhood={null}
								withCircle={false}
							/>
						</RowLayout>
					)}
				</div>
			</div>
			{!isEmpty(location?.highlightedAccommodations) && (
				<div className="bg-gray-100 py-12">
					<div className="container">
						<h2 className="mb-4 text-2xl font-bold md:mb-7">
							Book apartments, luxury villas and vacation rentals in{' '}
							{location.translation.name}
						</h2>
						<div className="5 -mx-2 flex flex-wrap">
							{location.highlightedAccommodations.map(product => (
								<AccommodationCard
									key={product.code}
									className={`mb-8 w-full px-2.5 sm:mb-9.5 sm:w-1/2 lg:w-1/3 xl:w-1/4`}
									product={product}
								/>
							))}
						</div>
						<div className="flex justify-center">
							<Button
								href={`${locationCode}/accommodations`}
								text="Show all accommodation"
								className="mt-6"
							/>
						</div>
					</div>
				</div>
			)}

			{!isEmpty(accommodationTaxons?.children) && (
				<div className="bg-gray-100 py-12">
					<div className="container">
						<h2 className="mb-4 text-2xl font-bold md:mb-7">
							Popular vacation rentals styles in {location.translation.name}
						</h2>
						<div className="5 -mx-2 flex flex-wrap">
							{accommodationTaxons?.children?.map(taxon => (
								<Card
									aspectRatio="7x9"
									key={taxon.code}
									className={`mb-8 w-full px-2.5 sm:mb-9.5 sm:w-1/2 lg:w-1/3 xl:w-1/4`}
									title={`${taxon?.translation?.name} in ${location?.translation?.name}`}
									imgSrc={getImageUrl(taxon?.mainImage?.imagePath)}
									href={`/${locationCode}/accommodations?type=${taxon?.code}`}
									sizes="(min-width: 1200px) 25vw, (min-width: 1024px) 33vw, (min-width: 768px) 50vw, 100vw"
								/>
							))}
						</div>
					</div>
				</div>
			)}

			{!!promoBanner && (
				<TipsSlider tipsArray={promoBanner?.acf?.promo_banner} />
			)}
			{!isEmpty(similarLocations['hydra:member']) && (
				<>
					<div className="container">
						<hr className="mb-20" />
						<h2 className="mb-4 text-2xl font-bold md:mb-7">Nearby cities</h2>
					</div>
					<LocationSlider
						className="mb-20"
						cities={similarLocations['hydra:member'].map(similarLocation => ({
							...similarLocation.locationTo,
							bottomSlot: <p>{similarLocation.distance} km</p>
						}))}
					/>
				</>
			)}
		</PlainLayout>
	);
};

export default CitySingle;

export async function getStaticPaths() {
	if (process.env.ENV === 'staging' || process.env.ENV === 'development') {
		return { paths: [], fallback: true };
	}

	let paths: { params: { channel: string } }[] = [];
	let locations = await getLocations({
		params: { itemsPerPage: 25 }
	});
	const locationsSlugs = locations['hydra:member'].map(location => {
		return {
			params: { channel: location?.code }
		};
	});

	paths = paths.concat(locationsSlugs);

	while (locations['hydra:view']['hydra:next']) {
		const locationsSlugs = locations['hydra:member'].map(location => {
			return {
				params: { channel: location?.code }
			};
		});
		const page =
			getParameterByName('page', locations['hydra:view']['hydra:next']) || 1;
		paths = paths.concat(locationsSlugs);

		locations = await getLocations({
			params: { page: Number(page), itemsPerPage: 25 }
		});
	}

	return {
		paths,
		fallback: true
	};
}

export async function getStaticProps({
	params
}: {
	params: { channel: string };
}) {
	const { channel } = params;

	const getTaxonsConfig = { params: { location: channel } };
	try {
		console.time(`: /${channel}`);
		const queryClient = new QueryClient();
		const [location, similarLocations, promoBanner] = await Promise.all([
			getLocation(channel),
			getSimilarLocations(channel, {
				params: { itemsPerPage: 4 }
			}),
			getPromoBannerData()
		]);

		if (!location) {
			throw new Error(`location with code '${channel}' not found`);
		}

		await Promise.all([
			queryClient.prefetchQuery(locationKeys.detail(channel), () => {
				return location;
			}),
			queryClient.prefetchQuery(
				[
					'similar-locations',
					channel,
					...getQueryKeyFromParams({ itemsPerPage: 4 })
				],
				() => {
					return similarLocations;
				}
			),
			queryClient.prefetchQuery(['promo-banner'], () => {
				return promoBanner;
			})
		]);

		// missing taxons return 404 from the backend so we need to handle them separately
		try {
			const accommodationTaxons = getTaxon('accommodation', getTaxonsConfig);
			await queryClient.prefetchQuery(
				taxonsKeys.detail('accommodation', getTaxonsConfig),
				() => {
					return accommodationTaxons;
				}
			);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
		console.timeEnd(`: /locations/${channel}`);

		return {
			props: {
				locationCode: channel,
				location,
				similarLocations,
				promoBanner,
				dehydratedState: dehydrate(queryClient)
			},
			revalidate: 60
		};
	} catch (e) {
		return {
			props: {
				locationCode: channel
			},
			notFound: true
		};
	}
}
