import axios, { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ITaxon } from './interfaces';
import { taxonsKeys } from './queryKeys';

interface IGetTaxonQueryParams {
	location?: string;
}

export interface IGetTaxonRequestConfig extends AxiosRequestConfig {
	params?: IGetTaxonQueryParams;
}

export const getTaxon = async (
	code: string,
	config?: IGetTaxonRequestConfig
) => {
	return await axios
		.get<ITaxon>(`/api/v2/shop/taxons/${code}`, config)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error('Error getting taxon by code: ', error);
			return Promise.reject(error);
		});
};

export function useGetTaxonQuery(
	code: string,
	config?: IGetTaxonRequestConfig,
	options?: UseQueryOptions<ITaxon, any, ITaxon>
) {
	return useQuery(
		taxonsKeys.detail(code, config),
		() => {
			return getTaxon(code, config);
		},
		options as any
	);
}
