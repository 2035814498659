import {
	Circle,
	GoogleMap,
	GoogleMapProps,
	useJsApiLoader
} from '@react-google-maps/api';
import { Button, Modal } from 'litto-lib/components/common';
import { getStaticMap } from 'litto-lib/utils';
import Image from 'next/image';
import React, { useState } from 'react';

const libraries: ('geometry' | 'places')[] = ['geometry', 'places'];

const mapStyles = [
	{
		featureType: 'poi',
		stylers: [
			{
				visibility: 'off'
			}
		]
	},
	{
		featureType: 'poi',
		stylers: [
			{
				visibility: 'off'
			}
		]
	}
];

const mapContainerStyle = {
	height: '450px',
	width: '100%'
};

const mapOptions:
	| GoogleMapProps['options']
	| any /* For some reason styles is not recognised in type */ = {
	styles: mapStyles,
	fullscreenControl: false,
	streetViewControl: false,
	mapTypeControl: false
};

const circleOptions = {
	strokeColor: '#0C3180',
	strokeOpacity: 0.8,
	strokeWeight: 2,
	fillColor: '#254da1',
	fillOpacity: 0.35,
	clickable: false,
	draggable: false,
	editable: false,
	visible: true,
	radius: 30000,
	zIndex: 1
};
export interface ILocationCardProps {
	className?: string;
	lat: number;
	lng: number;
	neighborhood: string | null;
	zoom?: number;
	withCircle?: boolean;
}

export const LocationCard: React.FC<ILocationCardProps> = ({
	lat,
	lng,
	className,
	neighborhood,
	zoom = 15,
	withCircle = true
}) => {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	useJsApiLoader({
		googleMapsApiKey: `${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}`,
		libraries
	});
	return (
		<div className={`${className || ''} `}>
			<div className="relative">
				{withCircle && (
					<div className="pin-c pointer-events-none z-10 w-1/4 rounded-full border-2 border-primary-500 bg-primary-500 bg-opacity-25 sm:w-1/5">
						<div className=" aspect-w-1 aspect-h-1"></div>
					</div>
				)}
				<button
					className="bg-pulse aspect-w-1 aspect-h-1 block w-full overflow-hidden rounded-lg transition-all sm:aspect-w-2 lg:aspect-w-3"
					onClick={() => setModalIsOpen(true)}
				>
					<Image
						layout="fill"
						objectFit="cover"
						src={getStaticMap(lat, lng, zoom)}
						alt={`${neighborhood} - ${lat} ${lng}`}
					/>
				</button>
			</div>
			{neighborhood && <p className="mt-5">{neighborhood}</p>}

			<Button
				className="mt-5"
				onClick={() => setModalIsOpen(true)}
				text="See location"
				variant="outline"
				color="primary"
				rounded="full"
				size="sm"
			/>
			<Modal
				isOpen={modalIsOpen}
				headerText="Location"
				onRequestClose={() => setModalIsOpen(false)}
			>
				{modalIsOpen && (
					<div data-testid="googleMap" className="overflow-hidden rounded-lg">
						<GoogleMap
							id="marker-example"
							mapContainerStyle={mapContainerStyle}
							zoom={zoom}
							options={mapOptions}
							center={{ lat, lng }}
						>
							{withCircle && (
								<Circle
									radius={65}
									center={{ lat, lng }}
									options={circleOptions}
								/>
							)}
						</GoogleMap>
					</div>
				)}
			</Modal>
		</div>
	);
};
