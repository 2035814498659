import { IGetTaxonRequestConfig } from './getTaxon';

export const taxonsKeys = {
	all: () => ['taxons'] as const,
	lists: () => [...taxonsKeys.all(), 'list'] as const,
	list: (filters: any) => [...taxonsKeys.lists(), { ...filters }] as const,
	details: () => [...taxonsKeys.all(), 'detail'] as const,
	detail: (code: string, config?: IGetTaxonRequestConfig) =>
		[
			...taxonsKeys.details(),
			code,
			{ ...(config?.params ? config?.params : {}) }
		] as const,
	detailBySlug: (slug: string) => [...taxonsKeys.details(), slug] as const
};
