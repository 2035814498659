import React from 'react';

import { SingleHero } from 'components/shared';
import { ILocation } from 'services/shop/interfaces';

export const LocationHero: React.FC<{ location: ILocation }> = ({
	location
}) => {
	const sources = React.useMemo(() => {
		let sources = location.images || [];
		if (location.mainImage) {
			sources = [location.mainImage, ...sources];
		}
		return sources;
	}, [location.images, location.mainImage]);

	return (
		<SingleHero
			title={`Holidays in ${location.translation.name}, Croatia`}
			images={sources}
			contentBottom={
				<>
					<div
						className="sm:my-4"
						dangerouslySetInnerHTML={{
							__html: location.translation.shortDescription
						}}
					/>
				</>
			}
		/>
	);
};
